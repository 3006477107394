import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Segment, Form, Icon, Menu } from 'semantic-ui-react';
import Toastify from 'toastify';
import api from '../../api';
import { redirect } from '../../lib/utils'; 
import { ApiCustomerObject } from '../../api/customers';
import { ApiUserObject } from '../../api/users';
import { ApiResellerObject } from '../../api/resellers';
import DomainLink from '../../components/DomainLink';
import FloatingButtons from '../../components/FloatingButtons';
import PageHeader from '../../components/Layout/PageHeader';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AppState } from '../../store';
import { ApiAuthObject } from '../../api/auth';

interface UsersEditViewProps extends RouteComponentProps<{ id?: string }> {
    auth?: ApiAuthObject,
}

interface UsersEditViewState {
    user: Partial<ApiUserObject>,
    customers: ApiCustomerObject[],
    resellers: ApiResellerObject[],
    isLoading: boolean,
    errors?: {
        [key: string]: any,
    },
}

class UsersEditView extends React.Component<UsersEditViewProps & WithTranslation, UsersEditViewState> {
    constructor(props: any) {
        super(props);
    
        this.state = {
            user: {
                reseller_id: undefined,
                role_id: 3,
                first_name: '',
                affix: '',
                last_name: '',
                email_address: '',
                '2fa_type': 'email',
                is_active: true,
                customer_ids: [],
            },
            customers: [],
            resellers: [],
            isLoading: false,
            errors: undefined,
        };
    }

    /**
     * Mount
     */
    componentDidMount = () => {
        if (!this.props.auth || this.props.auth.role_id !== 1) {
            if (this.props.match.params.id) {
                this.fetch();
            }
            return;
        }
        api.listResellers({ page: 1 }).then(({ data }) => {
            this.setState({
                resellers: data.data,
            });

            api.listCustomers({ page: 1 }, [], true).then(({ data }) => {
                this.setState({
                    customers: data.data,
                });

                if (this.props.match.params.id) {
                    this.fetch();
                }
            });
        });
    }

    /**
     * Fetch
     */
    fetch = () => {
        this.setState({ isLoading: true });

        if (this.props.match.params.id) {
            api.showUser(this.props.match.params.id, ['customers']).then(({ data }) => {
                this.setState({
                    user: data,
                    isLoading: false,
                });
            });
        }
    }

    /**
     * Handle input
     */
    handleInput = (e: any, data: any) => {
        const { user } = this.state;
        const newUser = user as any;
        newUser[data.name] = data.value;

        this.setState({
            user: newUser,
        });
    }

    /**
     * Save
     */
    save = (exit?: boolean) => {
        const { user } = this.state;
        this.setState({ isLoading: true, errors: undefined });
        
        if (this.props.match.params.id) {
            api.patchUser(this.props.match.params.id, user)
                .then(({ data }) => this.success(data, exit))
                .catch(this.onError);
        } else {
            api.storeUser(user).then(({ data }) => this.success(data, exit)).catch(this.onError);
        }
    }

    /**
     * Success
     */
    success = (data: ApiUserObject, exit?: boolean) => {
        const { t } = this.props;

        this.setState({
            user: data,
            isLoading: false,
        });
        Toastify.success(t('useredit.saved'));

        if (exit) {
            this.props.history.push(redirect('/users'));
        } else if (window.location.href.indexOf('create') !== -1) {
            this.props.history.push(redirect(`/users/${data.id}/edit`));
        }
    }

    /**
     * Error
     */
    onError = (error: any) => {
        const { t } = this.props;
        Toastify.error(t('useredit.somehting_went_wrong'));

        this.setState({
            isLoading: false,
            errors: error.response.data.errors,
        });
    }

    // render
    render = () => {
        const { t, auth } = this.props;
        const { user, isLoading, errors, resellers, customers } = this.state;
        const isReseller = auth && auth.role_id === 2;

        if (!resellers) {
            return null;
        }

        return (
            <div className="pageContainer">
                <PageHeader title={`${user.id ? t('useredit.edit_user') : t('useredit.new_user')}`}>
                    <DomainLink to="/users" primary>{t('useredit.back')}</DomainLink>
                </PageHeader>
                {user.id && (
                    <Menu>
                        <Menu.Item link onClick={() => api.sendUserInvite(user.id || '')}>
                            <Icon name="envelope outline" />
                            {t('useredit.send_invite')}
                        </Menu.Item>
                        <Menu.Item link onClick={() => api.superLogin(user.id || '')}>
                            <Icon name="lock" />
                            {t('useredit.login_as')}
                        </Menu.Item>
                    </Menu>
                )}
                <Segment loading={isLoading}>
                    <Form onSubmit={() => this.save()}>
                        <Form.Select
                            fluid
                            label="Status"
                            options={[{
                                key: `status-1`,
                                value: true,
                                text: t('useredit.active'),
                            }, {
                                key: `status-2`,
                                value: false,
                                text: t('useredit.inactive'),
                            }]}
                            name="is_active"
                            selectOnBlur={false}
                            value={user.is_active}
                            onChange={this.handleInput}
                        />
                        {!isReseller ? (<>
                            <Form.Select
                                error={errors && errors.reseller_id}
                                label={t('useredit.white_label')}
                                options={resellers.map((r) => ({
                                    key: `reseller-${r.id}`,
                                    value: r.id,
                                    text: r.name,
                                }))}
                                required={user.role_id !== 1}
                                name="reseller_id"
                                placeholder={t('useredit.select_white_label')}
                                selectOnBlur={false}
                                value={user.reseller_id}
                                onChange={this.handleInput}
                            />
                            {errors && errors.reseller_id && <p className="formError">{errors.reseller_id}</p>}
                            <Form.Select
                                label="Rol"
                                options={[{
                                    key: `role-1`,
                                    value: 1,
                                    text: t('useredit.superadmin'),
                                }, {
                                    key: `role-2`,
                                    value: 2,
                                    text: 'White Label',
                                }, {
                                    key: `role-5`,
                                    value: 5,
                                    text: 'CMSI360',
                                }, {
                                    key: `role-7`,
                                    value: 7,
                                    text: 'Sales',
                                }, {
                                    key: `role-3`,
                                    value: 3,
                                    text: t('useredit.customer'),
                                }, {
                                    key: `role-4`,
                                    value: 4,
                                    text: 'Manager',
                                }, {
                                    key: `role-6`,
                                    value: 6,
                                    text: 'Flexgebruiker',
                                }, {
                                    key: `role-8`,
                                    value: 8,
                                    text: 'Auditor',
                                }]}
                                required
                                name="role_id"
                                selectOnBlur={false}
                                value={user.role_id}
                                onChange={this.handleInput}
                            />
                            {(user.role_id === 3 || user.role_id === 4 || user.role_id === 5  || user.role_id === 8) && (<Form.Select
                                error={errors && errors.customer_ids}
                                label={t('useredit.customers')}
                                options={customers.map((c) => ({
                                    key: `reseller-${c.id}`,
                                    value: c.id,
                                    text: c.name,
                                }))}
                                required
                                name="customer_ids"
                                placeholder={t('useredit.select_customers')}
                                selectOnBlur={false}
                                multiple
                                search
                                value={user.customer_ids}
                                onChange={this.handleInput}
                            />)}
                            {errors && errors.customer_ids && <p className="formError">{errors.customer_ids}</p>}
                        </>) : (
                            <Form.Select
                                label="Rol"
                                options={[{
                                    key: `role-2`,
                                    value: 2,
                                    text: 'Beheerder',
                                }, {
                                    key: `role-7`,
                                    value: 7,
                                    text: 'Sales',
                                }]}
                                required
                                name="role_id"
                                selectOnBlur={false}
                                value={user.role_id}
                                onChange={this.handleInput}
                            />
                        )}
                        <div style={{ display: 'flex', marginBottom: 17 }}>
                            <div style={{ flex: 1 }}>
                                <Form.Input
                                    error={errors && errors.first_name !== undefined}
                                    label={t('useredit.first_name')}
                                    required
                                    name="first_name"
                                    value={user.first_name}
                                    onChange={this.handleInput}
                                />
                                {errors && errors.first_name && <p className="formError">{errors.first_name}</p>}
                            </div>
                            <div style={{ margin: '0 10px' }}>
                                <Form.Input
                                    label={t('useredit.affix')}
                                    name="affix"
                                    value={user.affix || ''}
                                    onChange={this.handleInput}
                                />
                            </div>
                            <div style={{ flex: 1 }}>
                                <Form.Input
                                    error={errors && errors.last_name !== undefined}
                                    label={t('useredit.last_name')}
                                    required
                                    name="last_name"
                                    value={user.last_name}
                                    onChange={this.handleInput}
                                />
                                {errors && errors.last_name && <p className="formError">{errors.last_name}</p>}
                            </div>
                        </div>
                        <Form.Input
                            error={errors && errors.email_address !== undefined}
                            label={t('useredit.emailaddress')}
                            required
                            name="email_address"
                            value={user.email_address}
                            onChange={this.handleInput}
                        />
                        {errors && errors.email_address && <p className="formError">{errors.email_address}</p>}
                        <Form.Select
                            label="2FA type"
                            options={[{
                                key: `tfa-1`,
                                value: 'email',
                                text: t('useredit.send_code_email'),
                            }, {
                                key: `tfa-2`,
                                value: 'google',
                                text: t('useredit.send_code_google'),
                            }]}
                            name="2fa_type"
                            selectOnBlur={false}
                            value={user['2fa_type']}
                            onChange={this.handleInput}
                            fluid
                        />
                    </Form>
                </Segment>
                <FloatingButtons
                    onCancel="/users"
                    onSave={() => this.save()}
                    onSaveExit={() => this.save(true)}
                />
            </div>
        );
    }
}

export default connect((state: AppState) => ({
    auth: state.auth.user,
 }))(withTranslation('common')(UsersEditView));
