import React from 'react';
import qs from 'qs';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Dropdown, Menu, Icon, Label } from 'semantic-ui-react';
import Toastify from 'toastify';
import styles from './styles.module.scss';
import { ApiAuthObject } from '../../api/auth';
import DomainLink from '../DomainLink';
import api from '../../api';
import Logo from '../../components/Logo';
import { logout, setAuth } from '../../reducers/auth';
import { AppState } from '../../store';
import SocketIo from './SocketIo';
import Jobs from '../Jobs';

interface TopBarProps extends RouteComponentProps {
    auth?: ApiAuthObject;
    logout: () => void,
    setAuth: (user: any) => void,
}

interface TopBarState {
    lang?: string,
}

class TopBar extends React.Component<TopBarProps & WithTranslation, TopBarState> {
    constructor (props: TopBarProps & WithTranslation) {
        super(props);

        let lang = localStorage.getItem('lang');

        if (!lang && props.auth && props.auth.selected_customer) {
            lang = props.auth.selected_customer.default_lang;
            localStorage.setItem('lang', lang || 'nl');
        }

        this.state = {
            lang: lang || 'nl',
        };
    }

    componentDidMount = () => {
        const { i18n } = this.props;
        const query = qs.parse(window.location.search.substring(1));
        if (query.sc) {
            this.setSelectedCustomer(parseInt(query.sc));
        }
        i18n.changeLanguage(this.state.lang);
    }

    setLang = (lang: string) => {
        const { i18n } = this.props;
        localStorage.setItem('lang', lang);
        this.setState({ lang });
        i18n.changeLanguage(lang);
    }

    /**
     * Logout
     */
    logout = () => {
        const { auth } = this.props;
        
        if (auth) {
            this.props.history.push(`/${auth.reseller ? auth.reseller.domain : 'cmsi360'}/login`);
            this.props.logout();
        }
    }

    /**
     * Set selected customer
     */
    setSelectedCustomer = (customerId: number) => {
        api.setSelectedCustomer(customerId).then(() => {
            this.props.setAuth({
                ...this.props.auth,
                selected_customer_id: customerId,
            });
            Toastify.success('Klant geswitched');
            window.location.href = window.location.href.split('?')[0];
        });
    }

    // --- render
    render() {
        const { auth, t } = this.props;
        const { lang } = this.state;

        if (!auth || !lang) {
            return null;
        }

        return (<>
            <div className={[styles.container, 'topBar'].join(' ')}>
                <div className={styles.innerContainer}>
                    <div>
                        <Logo />
                    </div>
                    <Menu secondary className={styles.navigation}>
                        {auth.role_id === 1 && (
                            <>
                            <Menu.Item active={!/customers|resellers|users|templates|invoices|actions|debtors/.test(window.location.href)}>
                                <DomainLink to="/">Dashboard</DomainLink>
                            </Menu.Item>
                            <Menu.Item active={/resellers/.test(window.location.href)}>
                                <DomainLink to="/resellers">White Labels</DomainLink>
                            </Menu.Item>
                            <Menu.Item active={/users/.test(window.location.href)}>
                                <DomainLink to="/users">{t('users.title')}</DomainLink>
                            </Menu.Item>
                            <Menu.Item active={/customers/.test(window.location.href)}>
                                <DomainLink to="/customers">{t('customers.multi')}</DomainLink>
                            </Menu.Item>
                            <Menu.Item active={/templates/.test(window.location.href)}>
                                <DomainLink to="/templates">{t('templates.title')}</DomainLink>
                            </Menu.Item>
                            </>
                        )}
                        {[2, 7].includes(auth.role_id) && (<>
                            <Menu.Item active={!/customers|resellers|users|templates|invoices|actions|debtors/.test(window.location.href)}>
                                <DomainLink to="/">Dashboard</DomainLink>
                            </Menu.Item>
                            <Menu.Item active={/customers/.test(window.location.href)}>
                                <DomainLink to="/customers">{t('customers.multi')}</DomainLink>
                            </Menu.Item>
                        </>)}
                        {auth.role_id === 2 && (
                            <Menu.Item active={/users/.test(window.location.href)}>
                                <DomainLink to="/users">{t('users.title')}</DomainLink>
                            </Menu.Item>
                        )}
                        {(auth.role_id === 3 || auth.role_id === 5 || auth.role_id === 8) && (
                            <>
                            <Menu.Item active={!/invoices|actions|debtors/.test(window.location.href)}>
                                <DomainLink to="/">Dashboard</DomainLink>
                            </Menu.Item>
                            {auth.role_id !== 8 && (
                                <Menu.Item active={/actions/.test(window.location.href)}>
                                    <DomainLink to="/actions">{t('actionlist.title')}</DomainLink>
                                </Menu.Item>
                            )}
                            <Menu.Item active={/debtors/.test(window.location.href)}>
                                <DomainLink to="/debtors">{t('debtors.title')}</DomainLink>
                            </Menu.Item>
                            <Menu.Item active={/invoices/.test(window.location.href)}>
                                <DomainLink to="/invoices">{t('invoices.title')}</DomainLink>
                            </Menu.Item>
                            {(auth.super_login || (auth.selected_customer && auth.id === auth.selected_customer.account_owner_id) || auth.id === 102) && (
                                <Menu.Item active={/ri-matches/.test(window.location.href)}>
                                    <DomainLink to="/ri-matches">RI Match</DomainLink>
                                </Menu.Item>
                            )}
                            </>
                        )}
                        {auth.role_id === 4 && (
                            <>
                            <Menu.Item active={!/invoices|actions|debtors/.test(window.location.href)}>
                                <DomainLink to="/">Dashboard</DomainLink>
                            </Menu.Item>
                            </>
                        )}
                        {auth.role_id === 6 && (
                            <>
                            <Menu.Item active={/debtors/.test(window.location.href)}>
                                <DomainLink to="/debtors">Debiteuren</DomainLink>
                            </Menu.Item>
                            </>
                        )}
                    </Menu>
                    <div className={styles.profile}>
                        {auth.selected_customer && <div>
                            {t('general.welcome')} {auth.selected_customer.name}
                        </div>}
                        <Dropdown text={auth.full_name} className={styles.profileName}>
                            <Dropdown.Menu>
                                {auth.customers && Object.values(auth.customers).map((c: any) => (
                                    <Dropdown.Item
                                        onClick={() => this.setSelectedCustomer(c.id as number)}
                                        key={`customer-${c.id}`}
                                    >
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <div style={{ flex: 1 }}>
                                                <Icon name={auth.selected_customer_id === c.id ? 'check' : 'circle outline'} />
                                                {c.name}
                                            </div>
                                            {c.action_list > 0 && <Label content={c.action_list} color="red" style={{ marginLeft: 10 }} size="tiny" />}
                                        </div>
                                    </Dropdown.Item>
                                ))}
                                <Dropdown.Divider />
                                {(auth.role_id !== 4 && auth.role_id !== 6) && <>
                                    <Dropdown.Item>
                                        <DomainLink to={`/profile/${auth.selected_customer_id}`} default>{t('customers.title')}</DomainLink>
                                    </Dropdown.Item>
                                    {auth.role_id !== 8 && (
                                        <Dropdown.Item>
                                            <DomainLink to="/synchronize" default>{t('synchronize.title')}</DomainLink>
                                        </Dropdown.Item>
                                    )}
                                </>}
                                <Dropdown.Item text={auth.super_login ? t('general.back_to_account') : t('general.logout')} onClick={this.logout} />
                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown
                            icon={<img src={`/${lang}.svg`} alt="" style={{ width: 16 }} />}
                            style={{ marginLeft: 16 }}
                        >
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    icon={<img src={`/nl.svg`} alt="" style={{ width: 32 }} />}
                                    onClick={() => this.setLang('nl')}
                                />
                                <Dropdown.Item
                                    icon={<img src={`/gb.svg`} alt="" style={{ width: 32 }} />}
                                    onClick={() => this.setLang('gb')}
                                />
                                <Dropdown.Item
                                    icon={<img src={`/de.svg`} alt="" style={{ width: 32 }} />}
                                    onClick={() => this.setLang('de')}
                                />
                            </Dropdown.Menu>
                        </Dropdown>

                        {/* {auth.downloads > 0 && (<div
                            style={{ marginLeft: 15 }}
                        >
                            <DomainLink to="/letters">
                                <Icon name="file pdf outline" size="large" />
                                <Label color="red" size="tiny">{auth.downloads}</Label>
                            </DomainLink>
                        </div>)} */}
                    </div>
                </div>
            </div>
            <Jobs />
            <SocketIo />
        </>);
    }
}

export default withTranslation('common')(withRouter(connect((state: AppState) => ({
    auth: state.auth.user,
}), {
    logout,
    setAuth,
})(TopBar)));
