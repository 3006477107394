import { AxiosPromise } from 'axios';
import { request } from './index';
import { ApiCustomerObject } from './customers';
import { ApiResellerObject } from './resellers';

export interface ApiAuthObject {
    id: number,
    hash_id: string,
    customers?: Partial<ApiCustomerObject>[],
    selected_customer_id?: number,
    selected_customer?: ApiCustomerObject,
    customer_interest: number,
    reseller: ApiResellerObject,
    role_id: number,
    first_name: string,
    affix: string,
    last_name: string,
    full_name: string,
    email_address: string,
    downloads: number,
    super_login?: boolean,
}

export default {
    get: (): AxiosPromise<ApiAuthObject> => {
        return request('auth/profile');
    },
    login: (emailAddress: string, password: string): AxiosPromise => {
        return request.post('auth/login', {
            email_address: emailAddress,
            password,
        });
    },
    superLogin: (userId: number | string): AxiosPromise => {
        return request.post(`auth/super-login/${userId}`).then((res) => {
            localStorage.setItem('token', JSON.stringify(res.data));
            window.location.reload();
            return res;
        })
    },
    resetPassword: (emailAddress: string): AxiosPromise => {
        return request.post('auth/reset-password', {
            email_address: emailAddress,
        });
    },
    setNewPassword: (password: string): AxiosPromise => {
        return request.post(`auth/set-password?${window.location.search.split('?')[1]}`, {
            password,
        });
    },
    setSelectedCustomer: (customerId: number): AxiosPromise => {
        return request.patch('auth/set-customer', {
            id: customerId,
        });
    },
    verify: (code: string): AxiosPromise<ApiAuthObject> => {
        return request.post('auth/verify', {
            code,
        });
    },
};
