import qs from 'qs';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Segment, Form, Grid, Header, Button, Divider, Checkbox, Menu, Item, Icon, Popup } from 'semantic-ui-react';
import { WithTranslation, withTranslation } from 'react-i18next';
import Toastify from 'toastify';
import api from '../../api';
import { redirect, money } from '../../lib/utils'; 
import { ApiInvoiceObject } from '../../api/invoices';
import { ApiAuthObject } from '../../api/auth';
import { ApiDebtorObject } from '../../api/debtors';
import { ApiInvoiceStatusObject } from '../../api/invoicestatuses';
import FloatingButtons from '../../components/FloatingButtons';
import PageHeader from '../../components/Layout/PageHeader';
import Timeline from '../../components/Timeline';
import Journal from '../../components/Journal';
import { AppState } from '../../store';
import MoneyInput from '../../components/MoneyInput';
import AddNote from './AddNote';

interface InvoicesEditViewProps extends RouteComponentProps<{ id?: string }> {
    auth: ApiAuthObject,
}

interface InvoicesEditViewState {
    debtors: ApiDebtorObject[],
    invoice: Partial<ApiInvoiceObject>,
    isLoading: boolean,
    statuses: ApiInvoiceStatusObject[],
    errors?: {
        [key: string]: any,
    },
    tab: number,
}

class InvoicesEditView extends React.Component<InvoicesEditViewProps & WithTranslation, InvoicesEditViewState> {
    private dateTimer: any;

    constructor(props: any) {
        super(props);
    
        const query = qs.parse(window.location.search);

        this.state = {
            debtors: [],
            invoice: {
                debtor_id: parseInt(query['?debtor_id'] || 0),
                currency: 'EUR',
                number: '',
                date: '',
                information: '',
                amount: 0.00,
                comment: '',
                invoice_status_id: undefined,
                expires_at: '',
                interest: 0.00,
                incasso_costs: 0.00,
                send_flex: false,
            },
            statuses: [],
            isLoading: false,
            errors: undefined,
            tab: 0,
        };
    }

    /**
     * Mount
     */
    componentDidMount = () => {
        const { auth } = this.props;

        api.listStatuses(auth.selected_customer && auth.selected_customer.license_type === 'flex').then(({ data }) => {
            this.setState({
                statuses: data,
            });
        
            if (this.props.match.params.id) {
                this.fetch((data) => {
                    if (data.debtor) {
                        this.setState({
                            debtors: [data.debtor],
                        });
                    }
                });
            } else {
                api.listDebtors(undefined, ['all']).then(({ data }) => {
                    this.setState({
                        debtors: data.data,
                    });
                });
            }
        });
    }

    
    /**
     * Fetch
     */
    fetch = (callback?: (data: ApiInvoiceObject) => void) => {
        this.setState({ isLoading: true });

        if (this.props.match.params.id) {
            api.showInvoice(this.props.match.params.id, true).then(({ data }) => {
                this.setState({
                    invoice: data,
                    isLoading: false,
                }, () => callback ? callback(data) : undefined);
            });
        }
    }

    /**
     * Handle input
     */
    handleInput = (e: any, data: any) => {
        const { invoice } = this.state;
        const newInvoice = invoice as any;
        if (data.name === 'amount' || data.name === 'incasso_costs' || data.name === 'interest') {
            newInvoice[data.name] = data.value;
        } else {
            newInvoice[data.name] = data.checked === undefined ? data.value : data.checked;
        }

        if (data.name === 'total_amount' && !invoice.id) {
            newInvoice['amount'] = data.value;
        }

        if (data.name === 'date' && (!invoice.expires_at || invoice.expires_at === '')) {
            clearTimeout(this.dateTimer);
            this.dateTimer = setTimeout(() => {
                this.handleInput(null, { name: 'expires_at', value: moment(data.value).add(30, 'days').format('YYYY-MM-DD') });
            }, 1000);
        }

        this.setState({
            invoice: newInvoice,
        });
    }

    /**
     * Handle debtor input
     */
    handleDebtorInput = (e: any, data: any) => {
        const { invoice } = this.state;
        
        if (invoice.debtor) {
            const debtor = invoice.debtor as any;
            debtor[data.name] = data.value;
            invoice.debtor = debtor;

            this.setState({
                invoice,
            });
        }
    }

    /**
     * Save
     */
    save = (exit?: boolean) => {
        const { invoice } = this.state;
        this.setState({ isLoading: true, errors: undefined });
        
        if (this.props.match.params.id) {
            api.patchInvoice(this.props.match.params.id, invoice)
                .then(({ data }) => this.success(data, exit))
                .catch(this.onError);
        } else {
            api.storeInvoice(invoice).then(({ data }) => this.success(data, exit)).catch(this.onError);
        }
    }

    /**
     * Success
     */
    success = (data: ApiInvoiceObject, exit?: boolean) => {
        const { t } = this.props;

        this.setState({
            invoice: data,
            isLoading: false,
        });
        Toastify.success(t('invoices.saved'));

        if (exit) {
            this.props.history.push(redirect('/invoices'));
        } else if (window.location.href.indexOf('create') !== -1) {
            this.props.history.push(redirect(`/invoices/${data.id}/edit`));
        }
    }

    /**
     * Error
     */
    onError = (error: any) => {
        const { t } = this.props;
        Toastify.error(t('general.something_went_wrong'));

        this.setState({
            isLoading: false,
            errors: error.response.data.errors,
        });
    }

    // render
    render = () => {
        const { auth, t } = this.props;
        const { debtors, invoice, isLoading, errors, statuses, tab } = this.state;

        let interest = undefined;

        if (invoice && !invoice.interest && invoice.calculate_interest !== false) {
            let diff = 0;
            if (invoice.expires_at) {
                diff = moment().diff(invoice.expires_at, 'days');
            }

            interest = (((auth ? auth.customer_interest : 0) * (invoice.amount || 0)) / 365) * diff;
            interest = Math.round(interest * 100) / 100;
            interest = interest <= 0 ? 0 : interest;
            interest = parseFloat(interest as unknown as string).toFixed(2);
        }

        let incasso_costs = undefined;

        if (invoice && !invoice.incasso_costs && invoice.calculate_incasso !== false) {
            if (auth && auth.selected_customer) {
                const value = auth.selected_customer.incasso_value;

                if (auth.selected_customer.incasso_type === 'perc') {
                    incasso_costs = (value / 100) * (invoice.amount || 0);
                } else if (auth.selected_customer.incasso_type === 'costs') {
                    incasso_costs = value;
                } else if (auth.selected_customer.incasso_type === 'wik' && invoice.amount) {
                    const s1 = Math.min(invoice.amount, 2500);
                    const s2 = Math.min(invoice.amount - s1, 2500);
                    const s3 = Math.min(invoice.amount - s1 - s2, 5000);
                    const s4 = Math.min(invoice.amount - s1 - s2 - s3, 195000);
                    
                    incasso_costs = (s1 * .15) + (s2 * .1) + (s3 * .05) + (s4 * 0.01);
                    incasso_costs = incasso_costs > 6775 ? 6775 : incasso_costs;
                    incasso_costs = Math.round(incasso_costs * 100) / 100;
                    incasso_costs = (incasso_costs < (value || 0) ? (value || 0) : incasso_costs);
                    incasso_costs = parseFloat(incasso_costs as unknown as string).toFixed(2);
                }
            }
        }

        return (
            <div className="pageContainer">
                <PageHeader title={invoice.id ? t('invoices.edit_invoice') : t('invoices.add_invoice')}>
                    <a className="primary" href="/" onClick={(e) => {
                        this.props.history.goBack();
                        return e.preventDefault();
                    }}>{t('general.back')}</a>
                </PageHeader>

                <Form onSubmit={() => this.save()} loading={isLoading}>
                    <Grid columns="equal">
                        <Grid.Row>
                            <Grid.Column>
                                <Segment>
                                    <Header as="h3">{t('invoices.info')}</Header>
                                    {!invoice.id ? (<Form.Select
                                        error={errors && errors.debtor_id}
                                        label="Debiteur"
                                        options={debtors.map((d) => ({
                                            key: `debtor-${d.id}`,
                                            value: d.id,
                                            text: `${d.number} - ${d.name}`,
                                        }))}
                                        required
                                        search
                                        name="debtor_id"
                                        placeholder={t('invoices.select_debtor')}
                                        selectOnBlur={false}
                                        value={invoice.debtor_id}
                                        onChange={this.handleInput}
                                        disabled={invoice.id ? true : false}
                                    />) : (
                                        <div className="field">
                                            <label>{t('invoices.debtor')}</label>
                                            {invoice.debtor ? `${invoice.debtor.number} - ${invoice.debtor.name}` : ''}
                                        </div>
                                    )}
                                    {errors && errors.debtor_id && <p className="formError">{errors.debtor_id}</p>}
                                    <Form.Input
                                        error={errors && errors.number !== undefined}
                                        label={t('invoices.number')}
                                        name="number"
                                        onChange={this.handleInput}
                                        required
                                        value={invoice.number}
                                        readOnly={invoice.id}
                                    />
                                    {errors && errors.number && <p className="formError">{errors.number}</p>}
                                    <Form.Input
                                        error={errors && errors.date !== undefined}
                                        label={t('invoices.date')}
                                        name="date"
                                        onChange={this.handleInput}
                                        required
                                        type="date"
                                        value={invoice.date}
                                        readOnly={invoice.id}
                                    />
                                    {errors && errors.date && <p className="formError">{errors.date}</p>}
                                    <Form.Input
                                        error={errors && errors.expires_at !== undefined}
                                        label={t('invoices.expiry_date')}
                                        name="expires_at"
                                        onChange={this.handleInput}
                                        required
                                        type="date"
                                        value={invoice.expires_at || ''}
                                        readOnly={invoice.id}
                                    />
                                    {errors && errors.expires_at && <p className="formError">{errors.expires_at}</p>}
                                    <Form.Input
                                        label={t('invoices.valuta')}
                                        name="currency"
                                        onChange={this.handleInput}
                                        value={invoice.currency || 'EUR'}
                                        readOnly={auth.role_id === 8}
                                    />
                                </Segment>
                                <Segment>
                                    <Header as="h3">{t('invoices.amounts')}</Header>
                                    <Form.Field>
                                        <label>{t('invoices.invoice_amount')}</label>
                                        <MoneyInput
                                            label={{ basic: true, content: invoice.currency || 'EUR' }}
                                            name="total_amount"
                                            onChange={this.handleInput}
                                            value={invoice.total_amount || 0.00}
                                            readOnly={auth.role_id === 8}
                                        />
                                    </Form.Field>
                                    <Divider />
                                    <Form.Field>
                                        <label>{t('invoices.open_amount')}</label>
                                        <MoneyInput
                                            label={{ basic: true, content: invoice.currency || 'EUR' }}
                                            name="amount"
                                            onChange={this.handleInput}
                                            value={invoice.amount}
                                            readOnly={auth.role_id === 8}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>{t('invoices.interest')}</label>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <MoneyInput
                                                label={{ basic: true, content: invoice.currency || 'EUR' }}
                                                name="interest"
                                                onChange={this.handleInput}
                                                value={interest || invoice.interest || ''}
                                                disabled={invoice.calculate_interest === false}
                                                readOnly={auth.role_id === 8}
                                            />
                                            <Checkbox
                                                toggle
                                                checked={invoice.calculate_interest === false}
                                                label={t('invoices.dont_calculate')}
                                                onChange={(e: any, data: any) => this.handleInput(e, { name: 'calculate_interest', value: !data.checked })}
                                                style={{ marginLeft: 10 }}
                                                readOnly={auth.role_id === 8}
                                            />
                                        </div>
                                    </Form.Field>
                                    <Form.Field>
                                        <label>{t('invoices.incassocosts')}</label>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <MoneyInput
                                                label={{ basic: true, content: invoice.currency || 'EUR' }}
                                                name="incasso_costs"
                                                onChange={this.handleInput}
                                                value={incasso_costs || invoice.incasso_costs || ''}
                                                disabled={invoice.calculate_incasso === false}
                                                readOnly={auth.role_id === 8}
                                            />
                                            <Checkbox
                                                toggle
                                                checked={invoice.calculate_incasso === false}
                                                label={t('invoices.dont_calculate')}
                                                onChange={(e: any, data: any) => this.handleInput(e, { name: 'calculate_incasso', value: !data.checked })}
                                                style={{ marginLeft: 10 }}
                                                readOnly={auth.role_id === 8}
                                            />
                                        </div>
                                    </Form.Field>
                                    <p style={{ borderTop: 'solid 2px #333333', paddingTop: 10, marginTop: 17 }}>
                                        {/* <span>
                                            a: {(invoice.amount || 0)}<br />
                                            i: {(interest as number || invoice.interest as number || 0)}<br />
                                            c: {(incasso_costs as number || invoice.incasso_costs as number || 0)}
                                        </span> */}
                                        <b>{t('invoices.open_amount')}: </b> {money(((invoice.amount || 0) * 1) + ((interest as unknown as number || invoice.interest as number || 0) * 1) + ((incasso_costs as number || invoice.incasso_costs as number || 0) * 1), 2, invoice.currency)}
                                    </p>
                                </Segment>
                                <Segment>
                                    <Header as="h3">{t('invoices.status')}</Header>
                                    <Form.Select
                                        name="invoice_status_id"
                                        onChange={this.handleInput}
                                        required
                                        options={statuses.map(s => ({
                                            id: `status-${s.id}`,
                                            text: s.name,
                                            value: s.id,
                                        }))}
                                        value={invoice.invoice_status_id || invoice.last_status_id}
                                        readOnly={auth.role_id === 8}
                                    />
                                    <Form.Input
                                        label="Deadline"
                                        name="deadline"
                                        type="date"
                                        onChange={this.handleInput}
                                        value={invoice.deadline  || ''}
                                        readOnly={auth.role_id === 8}
                                    />
                                    {[20, 29, 152, 181].includes(auth.selected_customer_id || 0) && (
                                        <Form.Input
                                            label="Opmerking"
                                            name="contract_number"
                                            onChange={this.handleInput}
                                            value={invoice.contract_number}
                                            readOnly={auth.role_id === 8}
                                        />
                                    )}
                                    {/* {auth.selected_customer &&
                                     auth.selected_customer.has_flex_template &&
                                     invoice.debtor && 
                                     invoice.debtor.template_id && (
                                        <Checkbox
                                            label="Verstuur flex template"
                                            name="send_flex"
                                            checked={invoice.send_flex}
                                            onChange={(e: any, data: any) => this.handleInput(e, { name: 'send_flex', value: data.checked })}
                                        />
                                    )} */}
                                </Segment>
                                {invoice.debtor && (<Segment>
                                    <Header as="h3">{t('customers.contact')}</Header>
                                    <Form.Input
                                        label={t('customers.contact_name')}
                                        name="contact_name"
                                        value={invoice.debtor.contact_name || ''}
                                        onChange={this.handleDebtorInput}
                                        readOnly={auth.role_id === 8}
                                    />
                                    <Form.Input
                                        label={t('customers.contact_email')}
                                        name="contact_email_address"
                                        value={invoice.debtor.contact_email_address || ''}
                                        onChange={this.handleDebtorInput}
                                        readOnly={auth.role_id === 8}
                                    />
                                    <Form.Input
                                        label={t('customers.contact_phone')}
                                        name="contact_telephone_number"
                                        value={invoice.debtor.contact_telephone_number || ''}
                                        onChange={this.handleDebtorInput}
                                        readOnly={auth.role_id === 8}
                                    />
                                </Segment>)}
                            </Grid.Column>
                            {invoice.id && <Grid.Column>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Header as="h3" style={{ marginBottom: 0 }}>
                                        {t('invoices.dossier')}
                                    </Header>
                                    <Popup
                                        trigger={<a
                                            className="ui primary icon button"
                                            href={invoice.dossier_url}
                                            target="_blank"
                                            style={{ marginTop: -11, position: 'relative' }}
                                            rel="noopener noreferrer"
                                        >
                                            <Icon name="download" />
                                        </a>}
                                        content="Download alle e-mails"
                                        inverted
                                        size="tiny"
                                    />
                                </div>
                                {auth.role_id !== 8 && <AddNote invoiceId={invoice.id} onAdd={() => this.fetch()} />}

                                <Menu secondary pointing>
                                    <Menu.Item
                                        active={tab === 0}
                                        onClick={() => this.setState({ tab: 0 })}
                                        content={t('invoices.dossier')}
                                    />
                                    <Menu.Item
                                        active={tab === 1}
                                        onClick={() => this.setState({ tab: 1 })}
                                        content={t('invoices.bookings')}
                                    />
                                </Menu>


                                {tab === 0 && invoice.timeline && <Timeline data={invoice.timeline} onDelete={this.fetch} />}
                                {tab === 1 && invoice.journal && <Journal data={invoice.journal} />}
                            </Grid.Column>}
                        </Grid.Row>
                    </Grid>
                </Form>
                {auth.role_id !== 8 && (
                    <FloatingButtons
                        onCancel="/invoices"
                        onSave={() => this.save()}
                        onSaveExit={() => this.save(true)}
                    />
                )}
            </div>
        );
    }
}

export default withTranslation('common')(connect((state: AppState) => ({
    auth: state.auth.user,
}), {})(InvoicesEditView));
