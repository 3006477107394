import React from 'react';
import { connect } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ApiAuthObject } from './api/auth';
import { ApiResellerObject } from './api/resellers';
import { logout, setAuth } from './reducers/auth';
import { setReseller } from './reducers/resellers';
import { AppState } from './store';
import api from './api';
import TopBar from './components/TopBar';
import DashboardView from './views/DashboardView';
import SalesDashboard from './views/DashboardView/Sales';
import ErrorView from './views/ErrorView';
import LoginView from './views/LoginView';
import ActionsListView from './views/ActionsListView';
import CustomersEditView from './views/CustomersView/edit';
import CustomersListView from './views/CustomersView';
import DebtorsListView from './views/DebtorsView';
import DebtorSubscriptionView from './views/DebtorsView/Subscriptions';
import DebtorsEditView from './views/DebtorsView/edit';
import InvoicesListView from './views/InvoicesView';
import InvoicesEditView from './views/InvoicesView/edit';
import DownloadsListView from './views/InvoicesView/downloads';
import ResellersListView from './views/ResellersView';
import ResellersEditView from './views/ResellersView/edit';
import TemplatesEditView from './views/TemplatesView/edit';
import TemplatesListView from './views/TemplatesView';
import UsersEditView from './views/UsersView/edit';
import UsersListView from './views/UsersView';
import RiMatchListView from './views/RiMatchView';
import ImportView from './views/import';

interface AppProps {
    auth?: ApiAuthObject;
    logout: () => void,
    setAuth: (data: ApiAuthObject) => void,
    setReseller: (data: ApiResellerObject) => void,
}

const history = createBrowserHistory();

class App extends React.Component<AppProps> {
    componentDidMount = () => {
        if (localStorage.getItem('token')) {
            api.getAuth().then(({ data }) => {
                this.props.setAuth(data);
                if (data.reseller) {
                    this.props.setReseller(data.reseller);
                }
                if (data.selected_customer) {
                    localStorage.setItem('global-currency', data.selected_customer.currency);
                }
                
                const url = window.location.href.split('/').splice(4);
                history.push(`/${data.reseller ? data.reseller.domain : 'cmsi360'}/${url.join('/')}`);
            }).catch(() => {
                if (window.location.href.indexOf('/login') === -1) {
                    history.push(`/${this.parseDomain()}/login`);
                }
            });
        } else if (!this.props.auth && window.location.href.indexOf('/login') === -1) {
            history.push(`/${this.parseDomain()}/login`);
        }
    }

    parseDomain = () => {
        return window.location.href.split('/')[3] || 'cmsi360';
    }

    render() {
        const routes = [];

        routes.push(<Route path="/:domain/error" exact component={ErrorView} key="Error" />);

        if (this.props.auth && this.props.auth.role_id === 1) {
            routes.push(<Route path="/:domain/customers/:id/edit" exact component={CustomersEditView} key="customer-edit" />);
            routes.push(<Route path="/:domain/customers/create" exact component={CustomersEditView} key="customer-create" />);
            routes.push(<Route path="/:domain/customers" exact component={CustomersListView} key="customers" />);

            routes.push(<Route path="/:domain/resellers/:id/edit" exact component={ResellersEditView} key="reseller-edit" />);
            routes.push(<Route path="/:domain/resellers/create" exact component={ResellersEditView} key="reseller-create" />);
            routes.push(<Route path="/:domain/resellers" exact component={ResellersListView} key="resellers" />);

            routes.push(<Route path="/:domain/templates/:id/edit" exact component={TemplatesEditView} key="template-edit" />);
            routes.push(<Route path="/:domain/templates" exact component={TemplatesListView} key="templates" />);

            routes.push(<Route path="/:domain/users/:id/edit" exact component={UsersEditView} key="user-edit" />);
            routes.push(<Route path="/:domain/users/create" exact component={UsersEditView} key="user-create" />);
            routes.push(<Route path="/:domain/users" exact component={UsersListView} key="users" />);

            routes.push(<Route path="/:domain" exact component={SalesDashboard} key="my-dashboard" />);
        } else if (this.props.auth && [2, 7].includes(this.props.auth.role_id)) {
            routes.push(<Route path="/:domain/customers/:id/edit" exact component={CustomersEditView} key="customer-edit" />);
            routes.push(<Route path="/:domain/customers/create" exact component={CustomersEditView} key="customer-create" />);
            routes.push(<Route path="/:domain/customers" exact component={CustomersListView} key="customers" />);

            routes.push(<Route path="/:domain" exact component={SalesDashboard} key="my-dashboard" />);

            if (this.props.auth && [2].includes(this.props.auth.role_id)) {
                routes.push(<Route path="/:domain/users/:id/edit" exact component={UsersEditView} key="user-edit" />);
                routes.push(<Route path="/:domain/users/create" exact component={UsersEditView} key="user-create" />);
                routes.push(<Route path="/:domain/users" exact component={UsersListView} key="users" />);
            };
        } else if (this.props.auth && (this.props.auth.role_id === 3 || this.props.auth.role_id === 5 || this.props.auth.role_id === 8)) {
            routes.push(<Route path="/:domain/debtors/:id/edit" exact component={DebtorsEditView} key="debtor-edit" />);
            routes.push(<Route path="/:domain/debtors/create" exact component={DebtorsEditView} key="debtor-create" />);
            routes.push(<Route path="/:domain/debtors" exact component={DebtorsListView} key="debtors" />);

            if (this.props.auth.selected_customer_id === 177) {
                routes.push(<Route path="/:domain/debtors/subscriptions" exact component={DebtorSubscriptionView} key="debtorssubscriptions" />);
            }
            
            routes.push(<Route path="/:domain/ri-matches" exact component={RiMatchListView} key="ri-matches" />);

            routes.push(<Route path="/:domain/invoices/:id/edit" exact component={InvoicesEditView} key="invoice-edit" />);
            routes.push(<Route path="/:domain/invoices/create" exact component={InvoicesEditView} key="invoice-create" />);
            routes.push(<Route path="/:domain/invoices" exact component={InvoicesListView} key="invoices" />);
            routes.push(<Route path="/:domain/letters" exact component={DownloadsListView} key="downloads" />);

            routes.push(<Route path="/:domain/actions" exact component={ActionsListView} key="actions" />);
            routes.push(<Route path="/:domain/synchronize" exact component={ImportView} key="synchronize" />);
            routes.push(<Route path="/:domain/profile/:id" exact component={CustomersEditView} key="profile" />)
            
            routes.push(<Route path="/:domain" exact component={DashboardView} key="dashboard" />);
        } else if (this.props.auth && this.props.auth.role_id === 4) {
            routes.push(<Route path="/:domain" exact component={DashboardView} key="dashboard" />);
        }  else if (this.props.auth && this.props.auth.role_id === 6) {
            routes.push(<Route path="/:domain/debtors/:id/edit" exact component={DebtorsEditView} key="debtor-edit" />);
            routes.push(<Route path="/:domain/debtors" exact component={DebtorsListView} key="debtors" />);
        }

        return (
            <>
            <Router history={history}>
                {this.props.auth && <TopBar />}
                <Switch>
                    <Route path="/:domain/login" exact component={LoginView} />
                    {routes}
                </Switch>
                {window.location.href.indexOf('staging') !== -1 && <><div className="fwarn fwarn--staging">!!! - STAGING - !!!</div><div style={{height: 70}} /></>}
                {window.location.href.indexOf('localhost') !== -1 && <><div className="fwarn fwarn--dev">!!! - LOCAL - !!!</div><div style={{height: 70}} /></>}
                <div style={{ position: 'fixed', zIndex: 1, width: 70, bottom: 15, right: 15 }}>
                    <a href="https://www.the-aipa.info/spaces/cmsi360.html" target="_blank" rel="noopener noreferrer">
                        <img src="/foundation-mark.png" alt="" style={{ width: '100%', opacity: .3 }} />
                    </a>
                </div>
            </Router>
            </>
        );
    }
}

export default connect((state: AppState) => ({
    auth: state.auth.user,
}), {
    logout,
    setAuth,
    setReseller,
})(App);
