import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { WithTranslation, withTranslation } from 'react-i18next';
import { request } from '../../api';
import { money, redirect } from '../../lib/utils';
import { currencies } from '../../lib/constants';

interface InvoiceStatusProps {
    history?: any,
    currency?: string,
    globalFilter: {
        query: string,
        trigger: number,
        r9: boolean,
    }
}

interface InvoiceStatusState {
    chartOptions: any,
    status?: any,
}

class InvoiceStatus extends React.Component<InvoiceStatusProps & WithTranslation, InvoiceStatusState> {
    constructor(props: any) {
        super(props);
   
        const self = this;
        const lc = localStorage.getItem('global-currency');

        this.state = {
            status: undefined,
            chartOptions: {
                credits: {
                    enabled: false,
                },
                chart: {
                    type: 'column'
                },
                title: {
                    text: ''
                },
                tooltip: {
                    headerFormat: '<b>{point.x}</b><br/>',
                    // pointFormat: (currencies[props.currency]||lc||'€')+' {point.y:.2f}',
                },
                plotOptions: {
                    column: {
                        colorByPoint: true,
                        dataLabels: {
                            enabled: true,
                            // format: (currencies[props.currency]||lc||'€')+' {point.y:.2f}',
                        }
                    },
                    series: {
                        cursor: 'pointer',
                        point: {
                            events: {
                                click: function (event: any) {
                                    const data = this as any;
                                    if (self.props.history) {
                                        self.props.history.push(redirect(`/invoices?status=${self.state.status[data.category]}&df=${self.props.globalFilter.query}${self.props.currency ? `&currency=${self.props.currency}` : ''}${self.props.globalFilter.r9 ? '&r9=1' : ''}`));
                                    };
                                },
                            },
                        }
                    },
                },
                xAxis: {
                    categories: [],
                },
                yAxis: {
                    title: {
                        text: ''
                    },
                },
                series: []
            },
        };
    }

    componentDidMount = () => {
        this.fetch();
    }

    componentWillReceiveProps = (props: InvoiceStatusProps) => {
        if (props.currency !== this.props.currency) {
            this.fetch(props.currency);
        }
        if (props.globalFilter.trigger !== this.props.globalFilter.trigger) {
            this.fetch();
        }
    }

    fetch = (c?: string) => {
        const { props } = this;
        const currency = c || this.props.currency;

        const q = this.props.globalFilter.query;
        const r9 = this.props.globalFilter.r9;

        request.get(`dashboard/invoice-status?currency=${currency}&q=${q}${r9 ? `&r9=1` : ''}`).then(({ data }) => {
            const { props } = this;
            const colors: any = [];

            data.categories.map((c: string) => {
                if ([
                    'Sommatie 1',
                    'Sommatie 2',
                    'Sommatie 3',
                    'Overgedragen aan derde',
                    'Overgedragen Juridisch',
                    'Overgedragen Repo',
                    'Failliet',
                ].indexOf(c) !== -1) {
                    colors.push('rgb(222, 0, 0)');
                } else {
                    colors.push('rgb(124, 181, 236)');
                }
                return c;
            })

            this.setState({
                status: data.status,
                chartOptions: {
                    colors,
                    tooltip: {
                        headerFormat: '<b>{point.x}</b><br/>',
                        // pointFormat: currencies[this.props.currency || '€']+' {point.y:.2f}',
                        pointFormatter: function () {
                            const self: any = this;
                            return money(self.y, 2, currencies[currency || 'EUR']);
                        },
                    },
                    plotOptions: {
                        column: {
                            colorByPoint: true,
                            dataLabels: {
                                enabled: true,
                                //format: currencies[this.props.currency || 'EUR']+' {point.y:.2f}',
                                formatter: function () {
                                    const self: any = this;
                                    return money(self.point.y, 2, currencies[currency || 'EUR']);
                                },
                            }
                        },
                    },
                    xAxis: {
                        categories: data.trans,
                    },
                    series: [{
                        name: props.t('debtors.open_saldo'),
                        data: data.data,
                    }],
                },
            })
        });
    }

    // render
    render = () => {
        const { chartOptions } = this.state;

        return (
            <div>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOptions}
                />
            </div>
        );
    }
}

export default withTranslation('common')(InvoiceStatus);
